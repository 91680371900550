.about-us {
    h1 {
        font-size: 48px;
        margin-bottom: 40px !important;
    }

    p {
        font-size: 18px;
        line-height: 35px;
    }
}
