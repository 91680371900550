.bg-main {
    background-color: #f6f6f6;
}

.btn-primary {
    border-radius: 100px;
    letter-spacing: 0;
    color: #ffffff;
    border: none;
    background: linear-gradient(90deg, #d40334, #98177e) !important;
}

.btn-secondary {
    border-radius: 100px;
}

.card-group {
    margin: auto !important;
    display: grid !important;
    justify-content: center !important;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
    grid-gap: 0.5rem !important;
    border-radius: 25px !important;

    .card {
        border-radius: 25px !important;
        margin: 10px !important;
        text-align: center;

        .card {
            border-radius: 25px !important;
            border: none;

            .card-body {
                border: none;
                border-radius: 25px !important;
            }
        }

        .card-body {
            border-radius: 0 0 50px 50px;
        }

        .card-footer {
            background: none;
            border: none;
            padding: 0;
            border-radius: 0 0 50px 50px;

            .btn {
                height: 50px;
                background: transparent linear-gradient(270deg, #98177e 0%, #ff4712 100%) 0 0
                    no-repeat padding-box;
                border-radius: 0 0 20px 20px;
            }
        }
    }
}

.modal-content {
    border-radius: 25px !important;
    padding: 0.5rem 2rem;
}

.button-toolbar {
    border: 2px solid #ff4712 !important;
    border-radius: 39px;

    .btn {
        padding: 0.5rem 1.75rem;

        &.disabled {
            opacity: 0.3;
        }
    }
}

.check-icon {
    color: #98177e !important;
}

.uncheck-icon {
    color: #ff4712 !important;
}

form {
    ul {
        margin: 0;
        padding: 0;
    }

    .required:after {
        content: ' *';
        color: #c20330;
    }
}

.footer {
    position: relative;

    form.text-right {
        position: absolute;
        left: 10%;
        bottom: 12%;
        background-color: #ffffff;
        color: #000000;
        border-radius: 10px;
        padding: 3rem !important;
    }

    form.text-left {
        position: absolute;
        right: 10%;
        bottom: 12%;
        background-color: #ffffff;
        color: #000000;
        border-radius: 10px;
        padding: 3rem !important;
    }

    .up-side {
        background-color: #f6f6f6;
        padding-bottom: 8rem;
    }

    p {
        font-size: 18px;
    }
}

.py-6 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
}

@media (max-width: map_get($grid-breakpoints, lg)) {
    form {
        position: static !important;
    }

    .modal-content {
        padding: 0;
    }

    .footer {
        form {
            padding: 3rem 2rem !important;

            h3 {
                margin-bottom: 40px !important;
            }
        }

        .copyright {
            font-size: 14px !important;
        }
    }

    .button-toolbar {
        .btn {
            padding: 0.5rem 1rem;
            font-size: 16px;

            &.disabled {
                opacity: 0.3;
            }
        }
    }
}
