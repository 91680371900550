.nav-bar {
    transition: all 0.3s ease-in-out;

    &.expanded {
        padding: 25px 16px !important;
    }

    .nav-link {
        font-size: 18px;
        margin: 0 10px;
    }

    .active {
        color: #c20330 !important;
    }
}
