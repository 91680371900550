.pricing {
    background-image: url('../img/bg-pricing.svg');
    background-color: rgba(255, 71, 18, 0.15) !important;
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
        font-size: 48px;
    }

    p {
        font-size: 20px;
    }

    .card-group {
        .card {
            padding-top: 0;

            .card-header {
                padding: 0 0 10px;
                border: none;
                background: none;

                .flag {
                    background: transparent linear-gradient(90deg, #c20330 0%, #98177e 100%) 0 0
                        no-repeat padding-box;
                    width: auto;
                    min-width: 130px;
                    padding: 10px 20px;
                    color: #ffffff;

                    &:first-child {
                        border-radius: 20px 0 48px 0;
                        text-align: left;
                    }

                    &:nth-child(2) {
                        border-radius: 0 20px 0 48px;
                        text-align: right;
                    }
                }
            }

            .card-body {
                background: transparent linear-gradient(179deg, #f8f8f8 0%, #ffffff 100%) 0 0
                    no-repeat padding-box;
            }

            h1 {
                font-size: 25px;

                small {
                    line-height: 50px;
                    font-size: 16px;
                }
            }

            p {
                font-size: 18px;
            }
        }
    }
}
