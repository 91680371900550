.offers {
    background-image: url('../img/bg-offers.svg');
    background-color: #f6f6f6;
    background-repeat: no-repeat;
    background-size: cover;

    .controls {
        width: 60px;
        height: 60px;
        background: transparent !important;
        border: 2px solid #98177e;
        color: #ff4712;
    }

    h1 {
        font-size: 44px;
        margin-bottom: 40px !important;
    }

    p {
        font-size: 18px;
        line-height: 35px;
    }

    .card-group {
        max-width: 1000px;

        .card {
            h1 {
                font-size: 25px;

                small {
                    line-height: 50px;
                    font-size: 16px;
                }
            }

            p {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: map_get($grid-breakpoints, lg)) {
    .offers {
        .card-group {
            max-width: 500px;
        }
    }
}

@media (max-width: map_get($grid-breakpoints, md)) {
    .offers {
        .card-group {
            max-width: 350px;
        }
    }
}
