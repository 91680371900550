.why-mdad {
    background-image: url('../img/bg-why-mdad.svg');
    background-color: #f6f6f6;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 150px !important;

    h1 {
        font-size: 48px;
        margin-bottom: 40px !important;
    }

    p {
        font-size: 18px;
        line-height: 35px;
    }

    .card-group {
        .card {
            h1 {
                font-size: 25px;

                small {
                    line-height: 50px;
                    font-size: 16px;
                }
            }

            p {
                font-size: 18px;
            }
        }
    }
}
