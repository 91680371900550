.preview {
    position: relative !important;
    height: 100vh !important;
    width: 100% !important;
    background-image: url('../img/main-bg.webp');
    background-color: #f6f6f6;
    background-position: 70% 70%;
    background-repeat: no-repeat;
    background-size: cover;

    .preview-container {
        width: 100%;
        height: 100%;

        .preview-content {
            position: absolute;
            margin-right: 120px;
            right: 0;
            bottom: 15%;
            text-align: right;

            h1 {
                font-size: 80px;
                margin-bottom: 15px;
            }

            p {
                font-size: 23px;
                margin-top: 0;
                margin-bottom: 80px;
                width: 400px;
            }

            .btn-primary {
                width: 230px;
                height: 65px;
                padding-top: 15px;
                font-size: 23px;
                text-align: center;
            }
        }
    }
}

@media (max-width: map_get($grid-breakpoints, lg)) {
    .preview {
        background-position: 85% 70%;

        .preview-container {
            .preview-content {
                margin-right: 30px !important;
                bottom: 10%;

                h1 {
                    font-size: 60px;
                    margin-bottom: 15px;
                    margin-right: 20px;
                }

                p {
                    font-size: 20px;
                    width: 200px;
                    text-align: right;
                }
            }
        }
    }
}
