/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Frutiger LT Arabic 55 Roman';
    font-style: normal;
    font-weight: normal;
    src: local('Frutiger LT Arabic 55 Roman'),
        url('../fonts/FrutigerLTArabic55Roman.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Arabic 45 Light';
    font-style: normal;
    font-weight: normal;
    src: local('Frutiger LT Arabic 45 Light'),
        url('../fonts/FrutigerLTArabic45Light.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Arabic 65 Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Frutiger LT Arabic 65 Bold'),
        url('../fonts/FrutigerLTArabic65Bold.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Arabic 75 Black';
    font-style: normal;
    font-weight: normal;
    src: local('Frutiger LT Arabic 75 Black'),
        url('../fonts/frutigerltarabic75black.woff') format('woff');
}
