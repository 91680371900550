@import 'variables';

@import '~bootstrap/scss/bootstrap';

* {
    font-family: 'Frutiger LT Arabic 55 Roman', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

html {
    scroll-behavior: smooth;
}

@import '~@fortawesome/fontawesome-free/css/all.min.css';

@import 'fonts';
@import 'nav-bar';
@import 'preview';
@import 'about-us';
@import 'why-mdad';
@import 'offers';
@import 'pricing';
@import 'utilities';
